<template>
  <div
    class="hero-wrapper"
    :class="`hero-background-color-${data.fields.backgroundColor} no-image-${!data.fields.image} ${!data.fields.image ? 'delay-entryx' : 'appear'} alignment-${data.fields.alignment} no-content-${!data.fields.headline && !data.fields.description} wrap-cta-${data.fields.ctaTarget && !data.fields.cta ? true : false} just-logos-${data.fields.imageSet && !data.fields.headline} remove-padding-bottom-${data.fields.removePadding ? data.fields.removePadding.indexOf('Bottom') > -1 : false}`"
    v-if="data.fields"
    @click="data.fields.ctaTarget && !data.fields.cta ? goToLink(data.fields.ctaTarget, data.fields.newWindow) : ''"
  >
    <div class="responsive-embed mobile" v-html="data.fields.code" v-if="data.fields.code"></div>
    <div class="responsive-embed desktop" v-html="data.fields.codeDesktop" v-if="data.fields.codeDesktop"></div>

    <div class="hero-image" :style="{backgroundImage: `url(${data.fields.image.fields.file.url})`}" v-if="data.fields.image"></div>
    <div class="hero-content" :class="{'bg-image': data.fields.image}" v-if="data.fields.headline || data.fields.imageSet || data.fields.description">
      <div class="hero-inner-content">
        <h4 v-if="data.fields.headline">{{data.fields.subtitle}}</h4>
        <h1>{{data.fields.headline}}</h1>

        <div class="image-set-wrapper" v-if="data.fields.imageSet">
          <div class="image-set-item" v-for="image in data.fields.imageSet" :style="{backgroundImage: `url(${image.fields.file.url})`}">
          </div>
        </div>

        <h2>{{data.fields.tagline}}</h2>

        <el-row v-if="data.fields.isTerms && data.fields.downloadUrl">
          <el-button
            class="delay-entry cta-button"
            type="warning"
            round
          ><a target="_blank" class="link-no-link" :href="data.fields.downloadUrl">{{data.fields.downloadUrlCta}}</a></el-button>
        </el-row>

        <div v-html="mdToHTML(data.fields.description)" v-if="data.fields.description" class="description"></div>

        <el-row v-if="data.fields.cta && data.fields.ctaTarget && !data.fields.isTerms">
          <el-button
            class="delay-entry cta-button"
            type="danger"
            round
            @click="goToLink(data.fields.ctaTarget, data.fields.newWindow)"
          >{{data.fields.cta}}</el-button>
        </el-row>
        <el-row v-if="data.fields.isTerms">
          <el-button
            class="delay-entry cta-button"
            type="danger"
            round
            @click="goBackOrRedirect(data.fields.ctaTarget)"
          >{{data.fields.cta}}</el-button>
        </el-row>
      </div>
    </div>
    <div class="chevron-cta" v-if="data.fields.ctaTarget && !data.fields.cta">
      <Icons
        class="icon"
        icon="chevron-right"
        size="64"
        :color="'rgba(255,255,255,0.4)'"
      ></Icons>
    </div>
  </div>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'Hero',

  transition: 'page',

  props: [ 'data' ],

  components: {
    Icons: () => import('Components/base/Icons')
  },

  methods: {
    goBackOrRedirect (value) {
      // window.history.back()
      setTimeout(() => {
        window.location.href = value
      }, 1)
    },
    goToLink (target, newWindow) {
      if (target.indexOf('https://') === 0 || newWindow) {
        window.open(target, '_blank')
        return
      }
      this.$router.push(target)
    },
    mdToHTML (markdown) {
      let options = {
        renderNode: {
          'embedded-asset-block': (node) =>
            `<img class="img-fluid" src="${node.data.target.fields.file.url}" width="100%"/>`
        }
      }
      return documentToHtmlString(markdown, options)
    },
  }
}
</script>

<style scoped lang="scss">
.mobile {
  display: block;
  @include breakpoint($tablet) {
    display: none
  }
}
.desktop {
  display: none;
  @include breakpoint($tablet) {
    display: block
  }
}
.responsive-embed {
  width: 320px;
  height: 0;
  padding-bottom: 440px;
  &.desktop {
    width: 600px;
    padding-bottom: 400px;
  }
}
.wrap-cta-true {
  position: relative;
  @include breakpoint($tablet) {
    transition: all 0.4s ease-in-out;
    h1,
    h2 {
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      h1,
      h2 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.03);
      }
      h2 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.08);
      }
    }
  }
  &.alignment-undefined {
    text-align: left;
    @include breakpoint($tablet) {
      text-align: center;
    }
  }
  .hero-content {
    padding-right: 3em;
    width: calc(100% - 4em);
    @include breakpoint($tablet) {
      padding-right: 1em;
      width: calc(100% - 2em);
      justify-content: center;
    }
  }
}
.chevron-cta {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  transform: translateX(-5px);
  max-width: 50px;
  overflow: hidden;
  @include breakpoint($air) {
    transform: translateX(-80px) scale(1.5);
  }
  @include breakpoint($fullhd) {
    transform: translateX(-80px) scale(1.5);
  }
}
.hero-image {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  min-height: 220px;

  @include breakpoint($tablet) {
    position: absolute;
    top: 0;
    margin-bottom: 1em;
  }
}

.hero-wrapper {
  position: relative;
  background-color: white;
  flex: 1;
  @include breakpoint($tablet) {
    height: 0;
    padding-bottom: 36%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @include breakpoint($air) {
    padding-bottom: 32%;
  }
  &.no-image-true {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include breakpoint($tablet) {
      height: auto;
      padding-bottom: 0;
      min-height: 0;
    }
  }
  &.hero-background-color-light {
    background-color: rgba($gray-light, 0.6);
  }
  &.hero-background-color-Magenta,
  &.hero-background-color-Purple {
    background-color: $color-wrapper-emphasis-lighter;
    h1 {
      color: rgba(white, 0.99);
    }
    h2 {
      color: rgba(white, 0.7);
    }
    h4 {
      color: rgba(white, 0.7);
    }
    button {
      background-color: $text-main !important;
      border-color: $text-main !important;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: rgba($text-main, 0.8) !important;
        border-color: rgba($text-main, 0.8) !important;
      }
    }
  }
  &.hero-background-color-Purple {
    background-color: $color-wrapper-emphasis;
  }
  &.hero-background-color-Dark {
    background-color: rgba($text-main, 0.99);
    h1 {
      color: rgba(white, 0.99);
    }
    h2 {
      color: rgba(white, 0.6);
    }
  }
  &.hero-background-color-Purple,
  &.hero-background-color-Magenta,
  &.hero-background-color-Dark {
    .description {
      color: rgba(white, 0.99);
    }    
  }
  h2 {
    color: rgba($text-main, 0.7);
  }
  &.no-content-true {
    @include breakpoint($tablet) {
      padding-bottom: 25%;
    }
    &.just-logos-true {
      @include breakpoint($tablet) {
        padding: 0;
      }
      .hero-content {
        padding-top: 0;
      }
    }
  }
}
.hero-content {
  padding: 0em 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  @include breakpoint($tablet) {
    flex-direction: row;
  }
  &.bg-image {
    @include breakpoint($tablet) {
      padding: 1em;
      height: calc(100% - 2em);
      position: absolute;
      width: calc(100% - 2em);
      top: 0;
      background: rgba(white, 0.69);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.hero-background-color-Dark,
.hero-background-color-Magenta,
.hero-background-color-Purple {
  .hero-content {
    padding: 1em;
  }
}
.alignment-Left {
  .hero-content {
    @include breakpoint($tablet) {
      text-align: left;
      width: 100%;
      &.bg-image {
        @include breakpoint($tablet) {
          align-items: flex-start;
          left: 0;
          width: calc(100% - 2em);
          max-width: calc(50% - 3em);
          padding-right: 3em;
          border-bottom-right-radius: 1500px;
        }
      }
      h1 {
        text-align: left;
      }
    }
  }
}
.alignment-Right {
  .hero-content {
    @include breakpoint($tablet) {
      text-align: right;
      &.bg-image {
        @include breakpoint($tablet) {
          align-items: flex-end;
          max-width: calc(50% - 3em);
          padding-left: 3em;
          border-bottom-left-radius: 1500px;
          right: 0;
        }
      }
      h1 {
        text-align: right;
      }
    }
  }
}
.alignment-undefined.no-image-false {
  .hero-content {
    @include breakpoint($tablet) {
      &.bg-image {
        background: rgba($text-main, 0.69);
      }
      text-align: center;
      h1 {
        color: white;
      }
      h2 {
        color: rgba(white, 0.8);
      }
    }
  }
}
.hero-background-color-Light {
  .description {
    color: $text-main !important;
  }
}


.image-set-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .image-set-item {
    width: 150px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 1em;
    @include breakpoint($tablet) {
      width: 200px;
      height: 120px;
      margin: 0.5em 1em;
    }
  }
}

h4 + h1 {
  margin-top: -20px;
  @include breakpoint($tablet) {
    margin-top: -30px;
  }
}
.cta-button {
  margin-bottom: 1em;
}
.description {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($fullhd) {
    max-width: 1200px;
  }
}
h1 {
  @include breakpoint($fullhd) {
    max-width: 1200px;
  }
}
.alignment-Left {
  .description {
    max-width: 100%;
    margin-left: 0;
    text-align: left;
    @include breakpoint($tablet) {
      text-align: left;
    }
  }
  .hero-inner-content {
    @include breakpoint($tablet) {
      margin-left: 0 !important;
    }
  }
}

.hero-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;
  @include breakpoint($tablet) {
    flex-direction: column;
    margin-right: 1em;
  }
}
.no-image-true {
  .hero-inner-content {
    @include breakpoint($tablet) {
      flex-direction: column;
      margin-right: 1em;
      margin-left: 1em;
    }
    @include breakpoint($air) {
      flex-direction: column;
      margin-right: 3em;
      margin-left: 3em;
    }
  }
}

.remove-padding-bottom-true {
  .hero-content {
    padding-bottom: 0;
  }
}

</style>
<style lang="scss">
.website-container a.link-no-link {
  text-decoration: none;
  color: white !important;
}
.website-container {
  .hero-background-color-Magenta,
  .hero-background-color-Dark,
  .hero-background-color-Purple {
    .description {
      p {
        a {
          color: white !important;
        }
      }
    }
  }  
}
.wrap-cta-true {
  position: relative;
  @include breakpoint($tablet) {
    h4 {
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      transition: all 0.4s ease-in-out;
      h4 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.03);
      }
    }
  }
}
.hero-wrapper.no-image-false {
  .description {
    color: $text-main;
    @include breakpoint($tablet) {
      color: rgba(white, 0.99);
    }
  }
}
.website-container {
  .hero-wrapper {
    img.img-fluid {
      @include breakpoint($tablet) {
        max-width: 400px;
        float: left;
        margin-right: 40px;
        margin-bottom: 40px;
      }
    }
    img.img-fluid + blockquote {
      @include breakpoint($tablet) {
        padding: 20px;
        clear: right;
        margin-left: 20px;
        display: inline;
        width: 100%;
        p {
          display: flex;
        }
      }
    }
    img.img-fluid + blockquote + h2,
    img.img-fluid + h2 {
      clear: both;
    }

    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>